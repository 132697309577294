import React from "react";
import styles, { layout } from "../style";
import Button from "./Button";
import { card } from "../assets";
import { motion } from "framer-motion";

const CardDeal = ({ scaleUpVariants }) => (
  <section className={layout.section}>
    {/* HEADING */}
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading1}>
        Transforming Digital Banking
        <br className="hidden sm:block" />
        Experience the Innovation with Digivast :
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Our focus lies in exploring uncharted territories
        in the fintech realm, both in terms of technology
        and geographical landscapes. We venture into new and
        untapped areas, spanning urban and rural landscapes,
        to bring modern banking solutions to diverse regions.
        We transcend virtual boundaries by employing
        AI-driven solutions, customer-centric applications,
        and products embedded with cybersecurity measures.
      </p>
      <Button styles="mt-10" type="button" text="DigiVast" />
    </div>
    {/* IMG */}
    <div className={`${layout.sectionImg}`}>
      <motion.img
        src={card}
        alt="card"
        className="w-[100%]"
        variants={scaleUpVariants}
        initial={scaleUpVariants.hidden}
        whileInView={scaleUpVariants.whileInView}
        viewport={{ once: true }}
      />
    </div>
  </section>
);

export default CardDeal;
