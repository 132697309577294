import React, { useEffect } from "react";
import styles from "../style";
import { ButtonScrollTop, Footer, NavbarPrivacy } from "../components";
import { useNavigate } from "react-router-dom";

const PrivacyPolicyPage = () => {

    const navigate = useNavigate();

    // const scrollToTop = () => {
    //     window.scrollTo({ top: 0, behavior: "smooth" });
    // };

    // Function to navigate back to the root page
    const goBackToRoot = () => {
        navigate("/");
    };

    // Scroll to top when the component mounts
    useEffect(() => {
        //  window.scrollTo({ top: 0, behavior: "smooth" });
        // window.scrollTo(0, 0);

        const scrollToTop = () => {
            const element = document.body || document.documentElement;
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        };

        scrollToTop();

    }, []);

    return (
        <div className="w-full overflow-hidden bg-primary">
            <ButtonScrollTop onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
            <div className={`${styles.paddingX} ${styles.flexCenter} `}>
                <div className={`${styles.boxWidth}`}>
                    <NavbarPrivacy />
                </div>
            </div>

            <div className="flex flex-col justify-start flex-1 ml-10 mr-10">
                <header>
                    <p className={`${styles.paragraph} `}>Privacy Policy</p>
                </header>
                <section>
                    <p className={`${styles.paragraph} max-w-[8000px] mt-2 ml-1 md:ml-2`}>

                        Welcome to JAIN DIGIVAST TECHNOLOGY AND CONSULTING LLP and its subsidiaries (referred to as 'JAIN DIGIVAST,' 'Company,' 'We,' 'Us,' 'Our'). We are dedicated to safeguarding the privacy of our users ('User,' 'You,' 'Your').
                        <br className='sm:block hidden' />
                        This Privacy Policy outlines the terms and conditions governing the collection, use, processing, disclosure, transfer, and protection of your personal information (referred to as 'Personal Information').

                        <br className='sm:block hidden' />We encourage you to thoroughly read this Privacy Policy to comprehend our policies and practices concerning the collection, use, processing, disclosure, transfer, and protection of your Personal Information.

                        <br className='sm:block hidden' />By utilizing our website and/or services, you affirm your agreement to the practices and policies outlined in this Privacy Policy. Furthermore, you explicitly acknowledge and consent to our collection, use, processing, disclosure, and transfer of your Personal Information as described in this Policy. By agreeing to these terms, you commit to being legally bound by the conditions stated in this Privacy Policy.


                        <br className='sm:block hidden' />I. Collection and Utilization of Personal Information

                        <br className='sm:block hidden' />In the context of this privacy policy, 'Personal Information' encompasses, but is not restricted to, details such as your name, date of birth, location, address, phone number, email ID, login credentials, password, device IP address, demographics, operating system of your computer, browser type, designation, company, country, telephone number, etc. When accessing specific sections of the JAIN DIGIVAST website, you may be prompted to provide additional Personal Information to enable JAIN DIGIVAST to process the corresponding request.

                        <br className='sm:block hidden' />Your Personal Information may be used for one or more of the following purposes: registration, creation of a user ID, providing access to preferred content based on your preferences, enhancing usability, fulfilling your requests, conducting market research, improving our website, troubleshooting or supporting products or services obtained from JAIN DIGIVAST, monitoring or recording interactions for quality assurance, or retaining evidence of a particular transaction or interaction, among other purposes.


                        <br className='sm:block hidden' />
                        <br className='sm:block hidden' />II. Implications of Withholding Personal Information

                        <br className='sm:block hidden' />We will clearly identify the fields that must be filled in as mandatory and those that are optional. It is at your discretion whether to furnish such information. Opting not to provide essential Personal Information necessary for processing your request may result in our inability to deliver the associated service.


                        <br className='sm:block hidden' />III. Contact Details


                        <br className='sm:block hidden' />Third Party Cookies

                        <br className='sm:block hidden' />When you visit JAIN DIGIVAST website, our advertisement partners, Google and Facebook, whom we have engaged for remarketing may introduce cookies. Based on your browsing of our website you may see our advertisements while browsing through our advertisement partner websites and/or their network websites.



                        <br className='sm:block hidden' />Such cookies would allow us to monitor the effectiveness of the advertisements and to make the advertisements more relevant to you. In order to opt out from receiving such advertisements, you may set your preferences on our partner websites.
                        <br className='sm:block hidden' />International Users And Choice Of Law
                        <br className='sm:block hidden' />No Warranties

                        <br className='sm:block hidden' />This website, the information and materials on the site, and any software made available on the Website, are provided “as is” without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose. There is no warranty of any kind, express or implied, regarding third party content. In spite of JAIN DIGIVAST’s best endeavors, there is no warranty on behalf of JAIN DIGIVAST that this Website will be free of any computer viruses. Some jurisdictions do not allow for the exclusion of implied warranties, so the above exclusions may not apply to you.

                        <br className='sm:block hidden' />This Site is controlled, operated and administered by JAIN DIGIVAST from its offices within India. JAIN DIGIVAST makes no representation that Materials on this Website are appropriate or available for use at any other location(s) outside India.
                        <br className='sm:block hidden' />Any access to this Website from territories where their contents are illegal is prohibited. You may not use the Website or export the Materials in violation of any applicable export laws and regulations. If You access this Website from a location outside India, You are responsible for compliance with all local laws.

                        <br className='sm:block hidden' />These Terms of Use shall be governed by the laws of India, without giving effect to its conflict of laws provisions. You agree that the appropriate court(s) in Bangalore, India, will have the exclusive jurisdiction to resolve all disputes arising under these Terms of Use and You hereby consent to personal jurisdiction in such forum.

                        <br className='sm:block hidden' />These Terms of Use constitutes the entire agreement between JAIN DIGIVAST and You with respect to Your use of the Website. Any claim You may have with respect to Your use of the Website must be commenced within one (1) year of the cause of action. If any provision(s) of this Terms of Use is held by a court of competent jurisdiction to be contrary to law then such provision(s) shall be severed from this Terms of Use and the other remaining provisions of this Terms of Use shall remain in full force and effect.

                        <br className='sm:block hidden' />By utilizing the JAIN DIGIVAST website and/or services, you grant access to your contacts' names, numbers, and email IDs ('Contact Information'). JAIN DIGIVAST may gather, store, and utilize this Contact Information to improve the results shared with other Users. To clarify, the Contact Information that JAIN DIGIVAST may access, collect, store, and use is explicitly confined to names, contact numbers, and email IDs. No other Contact Information of any kind will be accessed, collected, stored, or used by JAIN DIGIVAST.

                        <h1> Contact Sync</h1>

                        <br className='sm:block hidden' /> About – Your Connections. You can use the contact upload feature and provide us, if permitted by applicable laws, with the phone numbers in your address book on a regular basis, including those of users of our Services and your other contacts. If any of your contacts aren’t yet using our Services, we’ll manage this information for you in a way that ensures those contacts cannot be identified by us. Learn more about our contact upload feature here.

                    </p>
                    {/* Add more content as needed */}
                </section>
            </div>
            <br className='sm:block hidden' />
            {/* Back button */}
            <div className={`${styles.paddingX} ${styles.flexStart}`}>
                <button onClick={goBackToRoot} className="button-link">
                    Back to Home
                </button>
            </div>

            <div className={`bg-primary ${styles.paddingX}  ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;