import {
  //people01,
  //  people02,
  // people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  airbnb,
  binance,
  coinbase,
  dropbox,
  send,
  shield,
  agent,
  star,
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "solution",
    title: "Solution",
  },
  {
    id: "product",
    title: "Product",
  },
  {
    id: "consulting",
    title: "Consulting",
  },
  {
    id: "aboutus",
    title: "About Us",
  },

];

// Additional dropdown item
export const dropdownItem = {
  id: 'home',
  title: 'Other',
  subLinks: [
    { id: 'home', title: 'blog' },
    { id: 'addon', title: 'add-on-features' },
  ],
};

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Retail Banking Solutions",
    content:
      "At Digivast, we are committed to revolutionizing retail banking, offering innovative solutions that cater to the diverse needs of our customers, enhancing accessibility, convenience, and security in their day-to-day financial activities.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Corporate Banking Solutions",
    content:
      "Digivast Banking Solutions is dedicated to being the trusted partner for businesses seeking advanced, secure, and agile financial solutions. With our expertise and commitment to excellence, we aim to enable businesses to thrive, grow, and achieve their financial objectives in today's competitive global marketplace.",
  },
  {
    id: "feature-3",
    icon: agent,
    title: "Agent Banking Solution",
    content:
      "DigiVast Agent Banking enables individual agents or business outlets to provide banking services seamlessly through mobile devices or PoS devices. This cost-effective solution serves as a pathway to cater to both existing customers and the underserved and unbanked populations, particularly in developing countries.",
  },

  {
    id: "feature-4",
    icon: send,
    title: "Wallet Banking & Onboarding Solution",
    content:
      "DigiVast Mobile Wallet has transformed traditional banking, making physical wallets and visits to bank branches or ATMs obsolete. Its impact on in-person banking is profound, reshaping how financial transactions are conducted. Our SME consumer onboarding prioritizes simplicity, empowering businesses to focus on growth. DigiVast's mobile wallet eliminates the need for physical tools, ensuring access to essential financial resources in today's dynamic market, reflecting our commitment to streamlined banking.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    name: "Delivery Application",


  },
  {
    id: "feedback-2",
    name: "ERP Application",


  },
  {
    id: "feedback-3",
    name: "Digital Banking",


  },
  {
    id: "feedback-4",
    name: "Consulting",


  },
  // {
  //   id: "feedback-2",
  //   content:
  //     "customized as per banks need.",
  //   name: "Mobile App",
  //   title: "Mobile Banking",
  //   img: people02,
  // },
  // {
  //   id: "feedback-3",
  //   content:
  //     "",
  //   name: "Web Application",
  //   title: "Internet Banking",
  //   img: people03,
  // },

];

export const stats = [
  {
    id: "stats-1",
    title: "Digital Banking | ERP Suite | Delivery Application | Cloud & Infra Consulting",
    value: "",

  },
  // {
  //   id: "stats-2",
  //   title: "Trusted by Company",
  //   value: "230",
  //   prefix: "",
  //   suffix: "+",
  // },
  // {
  //   id: "stats-3",
  //   title: "Transaction",
  //   value: "230",
  //   prefix: "$",
  //   suffix: "M+",
  // },
];

export const footerLinks = [
  {
    title: "Products",
    links: [
      {
        name: "Digital Banking Suite",
        //  link: "https://www.jaindigivast.com/content/",
      },
      {
        name: "ERP Suite",
        //  link: "https://www.jaindigivast.com/how-it-works/",
      },
      {
        name: "Delivery Application",
        // link: "https://www.jaindigivast.com/create/",
      },
      {
        name: "Third-Party Integration",
        // link: "https://www.jaindigivast.com/explore/",
      },

    ],
  },
  {
    title: "Digital Banking Suite",
    links: [
      {
        name: "Retail Banking",

      },
      {
        name: "Corporate Banking",

      },
      {
        name: "Agency Banking",

      },
      {
        name: "Customer Onboaring",

      },
      {
        name: "Wallet Banking",

      },
    ],
  },

  {
    title: "Partner",
    links: [
      {
        name: "Blog",

      },
      {
        name: "Terms & Condition",

      },
      {
        name: "Privacy Policy",
        path: "/privacy-policy",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/company/jain-digivast-techonology-and-consulting-llp",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];
