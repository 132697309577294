import React, { useEffect } from "react";
import styles from "../style";
import { ButtonScrollTop, ContactForm, Footer, Navbar } from "../components";
//import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrows } from '@fortawesome/free-solid-svg-icons';

const AboutUsPage = () => {

    const scaleUpVariants = {
        hidden: {
            opacity: 0,
            scale: 0.5,
        },
        whileInView: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5,
            },
        },
        viewport: { once: true },
    };

    //const navigate = useNavigate();

    // const scrollToTop = () => {
    //     window.scrollTo({ top: 0, behavior: "smooth" });
    // };

    // Function to navigate back to the root page
    // const goBackToRoot = () => {
    //     navigate("/");
    // };

    // Scroll to top when the component mounts
    useEffect(() => {
        //  window.scrollTo({ top: 0, behavior: "smooth" });
        // window.scrollTo(0, 0);

        const scrollToTop = () => {
            const element = document.body || document.documentElement;
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        };

        scrollToTop();

    }, []);

    return (
        <div className="w-full overflow-hidden bg-primary">
            <ButtonScrollTop onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
            <div className={`${styles.paddingX} ${styles.flexCenter} `}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar />
                </div>
            </div>


            <motion.section
                className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow items-center`}
                variants={scaleUpVariants}
                initial={scaleUpVariants.hidden}
                whileInView={scaleUpVariants.whileInView}
                viewport={scaleUpVariants.viewport}
            >
                <div className="flex flex-col flex-1">
                    <h2 className={styles.heading1}>What We Do?
                        <br className='sm:block hidden' />
                        <br className='sm:block hidden' /> At  JAIN DIGIVAST, we are a slick bunch of people insanely passionate about all things fintech. We construct and shape fintech ecosystems through solutions that include Consulting services for advancement of core operations and processes, and off-the-shelf products for 360-degree digitization and data transformation.
                    </h2>
                    <br className='sm:block hidden' />
                    <h2 className={styles.heading2}>360 Degree Enterprise Transformation</h2>
                    <p className={`${styles.paragraph} max-w-[570px] mt-5`}>
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />
                        Core Banking
                        <br className='sm:block hidden' />
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />Digital Transformation (Customers)
                        <br className='sm:block hidden' />
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />Banking Analytics and Regulatory Compliance
                        <br className='sm:block hidden' />
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />Enterprise Resource Planning
                        <br className='sm:block hidden' />
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />Infrastructure Services
                        <br className='sm:block hidden' />
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />Managed Services
                        <br className='sm:block hidden' />
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />Digital Transformation (Documents, Processes)
                        <br className='sm:block hidden' />
                        <FontAwesomeIcon icon={faArrows} className="mr-2" />
                        Integrated Data Platform for Reporting and Analytics
                    </p>
                </div>


            </motion.section>

            <br className='sm:block hidden' />
            <h2 className={`${styles.heading1} max-w-[770px] ml-10 mr-10`}> People & Culture</h2>
            <br className='sm:block hidden' />
            <p className={`${styles.paragraph} max-w-[770px] ml-10 mr-10`}> we create bright encouraging spaces for people to be their best, as professionals and as individuals. We learn continuously and together, and plough our learnings back into delivering exceptionally. We are building diverse and inclusive teams while ensuring that all individuals get immense opportunities for growth and expression of their unique genius. We are fun, inclusive, passionate and committed.
                <br className='sm:block hidden' />  <br className='sm:block hidden' />
                <h2 className={`${styles.heading1} max-w-[770px] ml-10 mr-10`}>Values :</h2>
                Integrity | Empowerment | Collaboration | Excellence | Respect | Commitment
                <br className='sm:block hidden' />
                <br className='sm:block hidden' />
                <h2 className={`${styles.heading1} max-w-[770px] ml-10 mr-10`}>Innovation</h2>
                The cheer goes to a dedicated R&D team of banking technologists that works relentlessly towards improving frameworks, processes, development to offer solutions that are delivered faster, are more robust and futuristic. The recent launch of The Jain DigiVast Innovation Hub is close to our hearts and an expression of our insane passion for banking fintech.

            </p>
            <br className='sm:block hidden' />
            {/* Back button */}
            {/* <div className={`${styles.paddingX} ${styles.flexStart}`}>
                <button onClick={goBackToRoot} className="button-link">
                    Back to Home
                </button>
            </div> */}
            <ContactForm scaleUpVariants={scaleUpVariants} />

            <div className={`bg-primary ${styles.paddingX}  ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default AboutUsPage;