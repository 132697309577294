import React from "react";
import styles from "../style";
import { logo } from "../assets";
import { footerLinks, socialMedia } from "../constants";
import { Link } from "react-router-dom";
//import { footerLinks } from "../constants";
const Footer = () => (
  <footer className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className={`${styles.flexStart}  md:flex-row flex-col mb-8 w-full`}>

      {/* <img
        src={logo}
        alt="DigiVast logo"
        className="w-[266px] h-[220px] object-contain"
      /> */}
      <div className="flex flex-col justify-start flex-1 mr-10">
        <p className={`${styles.paragraph} max-w-[450px] mt-2 ml-0 md:ml-0`}>
          JAIN DIGIVAST TECHNOLOGY AND CONSULTING LLP
          <br className='sm:block hidden' />Phone : +91-7898200746
          <br className='sm:block hidden' />email : info@jaindigivast.com
          <br className='sm:block hidden' /> Address : # 70/3, Balagere, Bengaluru
          <br className='sm:block hidden' /> Karnataka - 560087
        </p>

      </div>

      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
        {footerLinks.map((footerLink) => (
          <div
            className="flex flex-col my-4 ss:my-0 min-w-[150px]"
            key={footerLink.title}
          >
            <h4 className="font-poppins font-medium text-[18px] leading-[27px]" style={{ color: '#FF9B05' }}>
              {footerLink.title}
            </h4>
            <ul className="mt-2 list-none">
              {footerLink.links.map((link, index) => (
                <li
                  key={link.name}
                  className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite cursor-pointer hover:text-secondary ${index !== footerLink.length - 1 ? "mb-2" : "mb-0"
                    }`}
                >
                  <Link to={link.path}>{link.name}</Link>
                  {/* {link.name} */}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>

    <div className="flex flex-col items-center justify-between w-full md:flex-row pt-6 border-t-[1px] border-t-[#3f3e45]">

      {<img
        src={logo}
        alt="DigiVast logo"
        className="w-[266px] h-[220px] object-contain"
      />}
      <div className="flex flex-row mt-6 md:mt-0">
        <p className="mr-4 text-orange-500">Follow Us On:</p>
        {socialMedia.map((social, index) => (
          <img
            src={social.icon}
            key={social.id}
            alt={social.id}
            className={`${index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
              } w-[21px] h-[21px] object-contain cursor-pointer`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div>


    </div>
    <div className="flex flex-col items-center justify-between w-full md:flex-row pt-6 border-t-[1px] border-t-[#3f3e45]">
      <p className="font-poppins font-normal text-center md:text-left text-[18px] leading-[27px] text-white">
        © Jain DigiVast , 2023 . All Rights Reserved.

        {/* <br /> Built by{" "}
        <a
          href="http://"
          className="cursor-pointer hover:text-secondary"
          rel="noopener noreferrer"
          target="_blank"
        >
          Emanuele Del Monte
        </a> */}

      </p>
      {/* <div className="flex flex-row mt-6 md:mt-0">
        {socialMedia.map((social, index) => (
          <img
            src={social.icon}
            key={social.id}
            alt={social.id}
            className={`${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            } w-[21px] h-[21px] object-contain cursor-pointer`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div> */}

    </div>
  </footer >

);



export default Footer;
