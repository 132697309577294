import React, { useState } from "react";
import { close, logo, menu } from "../assets";
import { navLinks, dropdownItem } from "../constants";
import { motion } from "framer-motion";


function Navbar() {
  // toggle open and close menu on mobile
  const [toggle, setToggle] = useState(false);

  const ulVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const logoVariants = {
    hidden: { opacity: 0, scale: 0 },
    show: {
      opacity: 1,
      scale: 2,
      transition: {
        duration: 1,
      },
    },
  };

  const liVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };



  // disable page scrolling if mobile navbar is open
  if (toggle) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  } else {
    window.onscroll = function () { };
  }

  return (
    <div className="flex items-center justify-between w-[100%] py-6">
      <div className="flex items-center justify-between w-[30%] py-4">
        {/* LOGO */}
        <motion.img
          src={logo}
          alt="Digivast"
          className="w-[120px] h-[100px] object-contain"
          variants={logoVariants}
          initial="hidden"
          animate="show"
        /></div>


      <div className="flex items-center justify-between w-[70%]">

        <nav className="flex items-center justify-between  w-full navbar">

          {/* DESKTOP NAVBAR */}
          <motion.ul
            className="items-center justify-end flex-1 hidden list-none sm:flex"
            variants={ulVariants}
            initial="hidden"
            animate="show"
          >
            {/* last navLinks has no margin right */}
            {navLinks.map((nav, index) => (
              <motion.li
                key={nav.id}
                className={`font-poppins font-normal cursor-pointer text-[16] ${index === navLinks.length - 1 ? "mr-0" : "mr-10"
                  } text-white hover:text-secondary`}
                variants={liVariants}
              >
                {/* href to pages ID */}
                <a href={`#${nav.id}`}>{nav.title}</a>
              </motion.li>
            ))}
            {/* Additional dropdown */}
            <motion.li
              key={dropdownItem.id}
              className="font-poppins font-normal cursor-pointer text-[16] mr-0 ml-10 text-white hover:text-secondary dropdown" // Add 'dropdown' class
              variants={liVariants}
            >
              <a href={`#${dropdownItem.id}`}>{dropdownItem.title}</a>
              {/* Dropdown content */}
              <ul className="sub-links">
                {dropdownItem.subLinks.map(subLink => (
                  <li key={subLink.id}>
                    <a href={`#${subLink.id}`}>{subLink.title}</a>
                  </li>
                ))}
              </ul>
            </motion.li>


          </motion.ul>


          {/* MOBILE NAVBAR */}
          <div className="flex items-center justify-end flex-1 sm:hidden">
            {/* dynamic img */}
            <motion.img
              src={toggle ? close : menu}
              alt="menu"
              className="w-[28px] object-contain"
              // switch between current and previous state
              onClick={() => setToggle((prev) => !prev)}
              // use layout's Framer Motion to animate without any code
              layout
              variants={logoVariants}
              initial="hidden"
              animate="show"
            />

            <div
              className={`${toggle ? "flex" : "hidden"
                } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar z-[1]`}
            >


              <ul className="flex flex-col items-center justify-end flex-1 list-none">
                {/* last navLinks has no margin right */}
                {navLinks.map((nav, index) => (
                  <li
                    key={nav.id}
                    className={`font-poppins font-normal cursor-pointer text-[16] ${index === navLinks.length - 1 ? "mb-0" : "mb-10"
                      } text-white hover:text-secondary`}
                    // switch between current and previous state
                    onClick={() => setToggle((prev) => !prev)}
                  >
                    {/* href to pages ID */}
                    <a href={`#${nav.id}`}>{nav.title}</a>
                  </li>
                ))}

                {/* Additional dropdown for mobile */}
                <motion.li
                  key={dropdownItem.id}
                  className={`font-poppins font-normal cursor-pointer text-[16] "mb-0" : "mb-10"} 
                  text-white hover:text-secondary dropdown`}
                  variants={liVariants}
                >
                  <a href={`#${dropdownItem.id}`}>{dropdownItem.title}</a>
                  {/* Dropdown content */}
                  <ul className={`sub-links ${toggle ? "open" : ""} pl-2`}>
                    {dropdownItem.subLinks.map(subLink => (
                      <li key={subLink.id} className="ml-2">
                        <a href={`#${subLink.id}`}>{subLink.title}</a>
                      </li>
                    ))}
                  </ul>
                </motion.li>

              </ul>

            </div>

          </div>

        </nav>

      </div>


    </div>

  );
}

export default Navbar;
