import React, { useEffect } from "react";
import styles from "../style";
import { ButtonScrollTop, Footer, Navbar } from "../components";
//import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from "../components/ServicesElements";
import Icon1 from "../assets/agent.svg"
import Icon2 from "../assets/agent.svg"
import Icon3 from "../assets/agent.svg"

const AddonFeatures = () => {

    const scaleUpVariants = {
        hidden: {
            opacity: 0,
            scale: 0.5,
        },
        whileInView: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5,
            },
        },
        viewport: { once: true },
    };

    //const navigate = useNavigate();

    // const scrollToTop = () => {
    //     window.scrollTo({ top: 0, behavior: "smooth" });
    // };

    // Function to navigate back to the root page
    // const goBackToRoot = () => {
    //     navigate("/");
    // };

    // Scroll to top when the component mounts
    useEffect(() => {
        //  window.scrollTo({ top: 0, behavior: "smooth" });
        // window.scrollTo(0, 0);

        const scrollToTop = () => {
            const element = document.body || document.documentElement;
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        };

        scrollToTop();

    }, []);

    return (
        <div className="w-full overflow-hidden bg-primary">
            <ButtonScrollTop onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
            <div className={`${styles.paddingX} ${styles.flexCenter} `}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar />
                </div>
            </div>


            <motion.section
                className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow items-center`}
                variants={scaleUpVariants}
                initial={scaleUpVariants.hidden}
                whileInView={scaleUpVariants.whileInView}
                viewport={scaleUpVariants.viewport}
            >


                <ServicesContainer id="services">

                    <div className="flex flex-col flex-1">
                        <h2 className={styles.heading1}>ADD ON FEATURES </h2>
                        <br className='sm:block hidden' />
                        <br className='sm:block hidden' />
                        <p className={`${styles.paragraph}  mr-0 ml-2`}>Building Adaptable and Customized features that provide high value and agility.
                            With the ever-expanding banking landscape, outpacing competitors demands relentless innovation and delivering top-notch user experiences. Banks and financial institutions in the BFSI sector must stay ahead of the competition by incorporating the latest features into their apps.
                        </p>
                        <br className='sm:block hidden' />

                    </div>

                    <ServicesH1></ServicesH1>
                    <ServicesWrapper>
                        <ServicesCard>
                            <ServicesIcon src={Icon1} />
                            <ServicesH2>QR Code Payment</ServicesH2>
                            <ServicesP>
                                QR (Scan & Pay) is a digital payment acceptance channel to facilitate the receipt of payments at merchants by scanning the QR Code.
                            </ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon2} />
                            <ServicesH2>Split Bills</ServicesH2>
                            <ServicesP>
                                Split bills is the easiest way to track bills and other shared expenses so everyone gets paid back, stress-free from “who owes who.”​</ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon3} />
                            <ServicesH2>Fraud Alerts​</ServicesH2>
                            <ServicesP>
                                Fraud alerts are triggered to monitor suspicious activity on user accounts, and information is sent about potential fraudulent activity.
                            </ServicesP>
                        </ServicesCard>

                        <ServicesCard>
                            <ServicesIcon src={Icon3} />
                            <ServicesH2>Sub Wallets​​</ServicesH2>
                            <ServicesP>
                                A sub-wallet is a digital wallet feature allowing customers to create multiple mWallets to organize, save, and control their spending.
                            </ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon3} />
                            <ServicesH2>Offline Payment </ServicesH2>
                            <ServicesP>
                                Offline payment is the feature that allows customers and merchants to make and receive payments using an on-device in the absence of the internet.
                            </ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon3} />
                            <ServicesH2>Instant Alert​</ServicesH2>
                            <ServicesP>
                                Notifications for merchant payment alerts on QR codes. Real-time  alert on mobile  in your preferred language.
                            </ServicesP>
                        </ServicesCard>
                    </ServicesWrapper>
                </ServicesContainer>

            </motion.section>


            <div className={`bg-primary ${styles.paddingX}  ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default AddonFeatures;