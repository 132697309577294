import React, { useState } from "react";
import { logo } from "../assets";
//import { navLinks } from "../constants";
import { motion } from "framer-motion";

function NavbarPrivacy() {
  // toggle open and close menu on mobile
  const [toggle] = useState(false);


  const logoVariants = {
    hidden: { opacity: 0, scale: 0 },
    show: {
      opacity: 1,
      scale: 2,
      transition: {
        duration: 1,
      },
    },
  };


  // disable page scrolling if mobile navbar is open
  if (toggle) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  } else {
    window.onscroll = function () { };
  }

  return (
    <div className="flex items-center justify-between w-[100%] py-6">
      <div className="flex items-center justify-between w-[30%] py-4">
        {/* LOGO */}
        <motion.img
          src={logo}
          alt="Digivast"
          className="w-[120px] h-[100px] object-contain"
          variants={logoVariants}
          initial="hidden"
          animate="show"
        /></div>

    </div>
  );
}

export default NavbarPrivacy;
