import React from "react";
import HomePage from "./page/HomePage";

import PrivacyPolicyPage from "./page/PrivacyPolicyPage";
//import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import AboutUsPage from "./page/AboutUsPage";
import AddonFeatures from "./page/AddonFeatures";
//import { Business } from "./components";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/solution" element={<HomePage />} />
        <Route path="/product" element={<HomePage />} />
        <Route path="/consulting" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/addon" element={<AddonFeatures />} />

      </Routes>
    </Router>
  );

};

export default App;